import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { SaveAltOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import type { useAnalyticsTracking } from 'src/hooks'
import { useLanguageAndCountry } from 'src/hooks/useLanguageAndCountry'
import { getActionForDownloadEvent } from 'src/utils/getActionForDownloadEvent'
import { reduceAssetModel } from 'src/utils/reduceAssetModel'

import {
  DOWNLOAD_CENTER_SERVICE_URL,
  SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY,
} from '@/constants'
import type { AssetModel, UseInfiniteHitsReturnType } from '@/types'

export const createDownloadCellRendererComponent =
  (
    sendEvent: UseInfiniteHitsReturnType['sendEvent'],
    trackDownload: ReturnType<typeof useAnalyticsTracking>['trackDownload'],
  ) =>
  ({ row }: { row: Hit<AssetModel> }) => {
    const { t } = useTranslation('download-center', {
      keyPrefix: 'mainPage.searchAndHitsPanel.assetsCart.buttons',
    })
    const { countryCode } = useLanguageAndCountry()
    const { locale } = useRouter()
    const reducedAsset = reduceAssetModel(row)

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      sendEvent('click', row, 'click download')
      trackDownload(getActionForDownloadEvent(e), e.currentTarget.getAttribute('href') ?? '', [
        reducedAsset,
      ])
    }

    return (
      <IconButton
        onClick={handleOnClick}
        href={`${DOWNLOAD_CENTER_SERVICE_URL}/assets/${
          row.assetId
        }?download=true&country=${countryCode.toLowerCase()}&locale=${locale}`}
        target="_blank"
        aria-label={`${t('download')} ${row.name}`}
        data-cy={`${SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY}-${row.assetId}`}
      >
        <SaveAltOutlined color="secondary" />
      </IconButton>
    )
  }
