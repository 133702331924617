import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import {
  type HeaderProps,
  NextComponentContext,
} from '@knauf-group/ct-designs/components/core/WebHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { getLanguageAndCountryCodes } from '@knauf-group/ct-shared-nextjs/client'
import { AuthComponent } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopAuthComponent/AuthComponent'
import { SearchComponent } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/SearchComponent/SearchComponent'
import { WebHeaderProvider } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/utils/WebHeaderProvider'
import type { SharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import type { WithAnalytics } from '@knauf-group/ct-shared-nextjs/web/analytics'
import { useReferenceWithOnClick } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { getNavLinksWithAnalytics } from '@knauf-group/ct-shared-nextjs/web/utils/navigation'
import { type Theme } from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { useFlag } from '@unleash/nextjs'

const DynamicSearchLogicComponent = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/SearchComponent/SearchLogicComponent'
    ).then((m) => m.SearchLogicComponent),
  {
    ssr: false,
  },
)

const DynamicMobileHeader = dynamic(() =>
  import('@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileHeader/MobileHeader').then(
    (m) => m.MobileHeader,
  ),
)

const DynamicDesktopHeader = dynamic(() =>
  import(
    '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopHeader/DesktopHeader'
  ).then((m) => m.DesktopHeader),
)

const DynamicDesktopLoggedSection = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopAuthComponent/LoggedSection'
    ).then((m) => m.LoggedSection),
  {
    ssr: false,
  },
)

const DynamicMobileAuthComponents = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileAuthComponents/MobileAuthComponents'
    ).then((m) => m.MobileAuthComponents),
  { ssr: false },
)

const DynamicMobileDrawerFooter = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileHeader/MobileDrawerFooter'
    ).then((m) => m.MobileDrawerFooter),
  { ssr: false },
)

type WebHeader2Props = {
  navigationLinks: SharedContentfulProps['headerEntries']
  appsConfig: IAppsConfig
  onClickLogo?: () => void
} & WithAnalytics &
  HeaderProps

export const WebHeader2 = ({
  analytics,
  appsConfig,
  onClickLogo,
  ...restProps
}: WebHeader2Props) => {
  const router = useRouter()

  const { countryCode } = getLanguageAndCountryCodes(router.locale ?? '')
  const searchEnabled = useFlag(`gs-${countryCode?.toLowerCase()}`)
  const authEnabled = useFlag(`login-${countryCode?.toLowerCase()}`)

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  const defaultOnClickLogo = () => router.push('/')

  // add analytics to the navigation links
  const { getReferenceWithOnClick } = useReferenceWithOnClick()

  const navigationLinks = analytics
    ? getNavLinksWithAnalytics(restProps.navigationLinks, {
        ...analytics,
        getReferenceWithOnClick,
      })
    : restProps.navigationLinks

  const nextComponents = useMemo(
    () => ({
      nextLinkComponent: NextLink,
      nextRouter: router,
    }),
    [router],
  )

  return (
    // @ts-ignore TODO something is not expected to be `null` here
    <NextComponentContext.Provider value={nextComponents}>
      <WebHeaderProvider
        appsConfig={appsConfig}
        navigationLinks={navigationLinks}
        onClickLogo={onClickLogo ?? defaultOnClickLogo}
      >
        {isDesktop ? (
          <DynamicDesktopHeader
            {...(searchEnabled && {
              SearchComponent: (
                <SearchComponent DynamicSearchLogicComponent={DynamicSearchLogicComponent} />
              ),
            })}
            {...(authEnabled && {
              AuthComponent: (
                <AuthComponent DynamicLoggedSection={DynamicDesktopLoggedSection} />
              ),
            })}
          />
        ) : (
          <DynamicMobileHeader
            {...(authEnabled && {
              DynamicMobileAuthComponents,
              DynamicMobileDrawerFooter,
            })}
            {...(searchEnabled && {
              SearchComponent: (
                <SearchComponent DynamicSearchLogicComponent={DynamicSearchLogicComponent} />
              ),
            })}
          />
        )}
      </WebHeaderProvider>
    </NextComponentContext.Provider>
  )
}
