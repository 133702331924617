import { useMemo } from 'react'
import { useRouter } from 'next/router'

const getHomePath = (params: { locale: string }) => `/${params.locale}`

export function useLocalePathGenerator() {
  const { locale } = useRouter()

  return useMemo(
    () => ({
      getHomePath: () => getHomePath({ locale }),
    }),
    [locale],
  )
}
