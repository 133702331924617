// to get the preferred result, we need to use the given Intl.DisplayNames lang
// for certain i18next languages
export const languageAndLocaleMapping = new Proxy(
  {
    sr: 'sr-Latn-RS',
    'sr-RS': 'sr-Latn-RS',
  },
  {
    get(mapping, code) {
      if (code in mapping) {
        return mapping[code]
      }
      return code
    },
  },
)
