import { capitalize } from 'lodash'

import { languageAndLocaleMapping } from './languageAndLocaleMapping'

export const inferLanguageLabelByLanguageCode =
  (targetLanguageCode: string) => (languageLabel: string) => {
    const languageNames = new Intl.DisplayNames(
      [languageAndLocaleMapping[targetLanguageCode]],
      {
        type: 'language',
      },
    )

    return capitalize(languageNames.of(languageLabel)) || languageLabel
  }
