const {
  CROWDIN_LOCALE_MAPPING,
  DEFAULT_CROWDIN_LOCALE,
} = require('@knauf-group/ct-shared-nextjs/utils/constants')
const I18NextHttpBackend = require('i18next-http-backend')

const isClient = typeof window !== 'undefined'

const CROWDIN_DISTRIBUTION_HASH = '7f009ee929f0583e9db70b8xey1'

const locales = Object.keys(CROWDIN_LOCALE_MAPPING)

const TRANSLATIONS_CACHE_CLEAR_INTERVAL_IN_SECONDS = parseInt(
  process.env.TRANSLATIONS_CACHE_CLEAR_INTERVAL || '86400', // 24h by default
  10,
)
const i18nextConfig = {
  i18n: {
    defaultLocale: 'default',
    locales,
  },
  fallbackLng: {
    default: [DEFAULT_CROWDIN_LOCALE],
    'nb-nb': ['nb-NO'],
  },
  ns: ['download-center', 'cms', 'dam-assets', 'global-search', 'shared-nextjs'],
  defaultNS: 'download-center',
  use: isClient ? [] : [I18NextHttpBackend],
  backend: {
    loadPath: (languages, namespaces) => {
      const lng = languages[0]
      const ns = namespaces[0]

      // used when fetching the latest version of the translations from Crowdin CDN
      const currentTimestamp = Date.now()

      return `https://distributions.crowdin.net/${CROWDIN_DISTRIBUTION_HASH}/content/main/${lng}/${ns}.json?timestamp=${currentTimestamp}`
    },
    reloadInterval: TRANSLATIONS_CACHE_CLEAR_INTERVAL_IN_SECONDS * 1000,
  },
  serializeConfig: false,
}

module.exports = i18nextConfig
